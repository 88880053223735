<template>
    <main>
        <div class="hero" :style="{ backgroundImage: `linear-gradient(rgba(0 0 0 / 0.3), rgba(0 0 0 / 0.3)), url(${(formImagesItem.image!==null?formImagesItem.image:formImagesItemDefault.image)})` }">
           <div v-if="formImagesItem" class="container container--xs text-center">
                <h1>{{formImagesItem.title}}</h1>
                <p>{{formImagesItem.subtitle}}</p>
           </div>
        </div>
        <section class="section pt-0">
            <div class="container">
                <p class="breadcrumb"><a href="/"><span class="icon-home-outline"></span></a> / <span>Cómo canjear premios</span></p>
                <h2><a href="#" @click="router.go(-1)"><img src="images/arrowleft.svg" alt="Club de Sastreria Barrington"></a> Cómo canjear premios</h2>
                <div class="grid grid--two-columns grid--gap-md py-lg pt-0">
                    <div>
                       <img src="images/cb_img_comocanjear-min_v1.jpg" alt="Club de Sastreria Barrington"> 
                    </div>
                    <div>
                        <p>Puedes usar tus puntos para canjear premios que serán anunciados en la misma web del <strong>CLUB BARRINGTON</strong>. Para canjear, elige el premio dentro de tu sesión en la zona de canjes, sigue los pasos indicados y envía tu solicitud. Para que puedas dar el canje por confirmado deberás recibir un mail con la aceptación de tu solicitud.</p>
                        <p>Puedes anular una reserva de canje hasta 72 horas luego de confirmada la transacción. Esta anulación se debe realizar vía correo electrónico a barrington@aris.com.pe La anulación no tiene penalidad por lo que se te devolverán los puntos automáticamente.</p>

                    </div>
                </div>
                
            </div>
        </section>
    </main>
</template>

<script>

import router from "@/router"
import { ref, computed } from "vue";
import API from "@/api";

export default {
    name: "HowRedeemPoint",
    setup() {

        const formImagesItem = ref([]);
        const formImagesItemDefault = ref([]);
        const getDefault = () =>{
            API.get("/api/head-global-page-internal").then(response => { 
                formImagesItemDefault.value.image =  response.data[0].image===null?null:process.env.VUE_APP_BASE_URL +  response.data[0].image;
            })
        }
        const getHeaderDetail = () => {      
            API.get('/api/image-head-page').then(response => {      
                formImagesItem.value = response.data.filter(x=> x.head_menu_navigation_detail===3)[0];
                    console.log('x',formImagesItem.value)

                if (formImagesItem.value!=undefined){
                    formImagesItem.value.image =  response.data[0].image===null?null:process.env.VUE_APP_BASE_URL + formImagesItem.value.image;
                }
            });
        }      

        return { router, formImagesItem, formImagesItemDefault, getDefault, getHeaderDetail }
    },
    mounted() {
        this.getDefault()
        this.getHeaderDetail();
    }
}
</script>